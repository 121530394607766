import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import DorperGoatfeature from '../../images/sheeps/dorper/sheep-feature.jpg'
import DorperGoatGallery1 from '../../images/sheeps/dorper/dorper-na-vistavke.jpg'
import DorperGoatGallery2 from '../../images/sheeps/dorper/dorper-v-zagone.jpg'
import DorperGoatGallery3 from '../../images/sheeps/dorper/dorpery-na-prodaju.jpg'
import DorperGoatGallery4 from '../../images/sheeps/dorper/plemennie-ovcy-dorper.jpg'
import DorperGoatGallery5 from '../../images/sheeps/dorper/plemennoy-dorper.jpg'


const DorperePoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Описание пророды овец Дорпер', url:'#', status:true}]

    const indicators = [
      
  ]

    const galleryImage = [
      {
        src: DorperGoatGallery1,
        width: 3,
        height: 2,
        alt: 'Дорпер порода овец',
        title: 'Дорпер порода овец'
      },
      {
        src: DorperGoatGallery2,
        width: 3,
        height: 2,
        alt: 'Дорпер порода овец',
        title: 'Дорпер порода овец'
      },
      {
        src: DorperGoatGallery3,
        width: 3,
        height: 2,
        alt: 'Дорпер порода овец',
        title: 'Дорпер порода овец'
      },
      {
        src: DorperGoatGallery4,
        width: 3,
        height: 2,
        alt: 'Дорпер порода овец',
        title: 'Дорпер порода овец'
      },
      {
        src: DorperGoatGallery5,
        width: 3,
        height: 2,
        alt: 'Дорпер порода овец',
        title: 'Дорпер порода овец'
      }
    ];
  return (
    <div>
        <SEO title="Допер порода овец: описание породы, цена, фото"
            description="В настоящее время порода Дорпер широко распространена в Южной Африке и стала там второй по численности поголовья.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Описание пророды овец Дорпер'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={'descrition-page'}
                      breadCrumbs={breadCramb}
                      indicators={indicators}>
            </TopBunerProduct>
        <Container fluid>
         <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify ">
                <div className="pl-md-5">
                    <h1 className="text-center text-md-left ">Порода овец Дорпер</h1>
                    <p className="text-justify">
                    Выведена порода овец Дорпер в Южной Африке в 30-х годах прошлого века путем скрещивания местных овец с 
                    баранами ДорсетХорн и Персидской Черноголовой.Общество заводчиков породы Дорпер в Южной Африке было создано в 1950 году. 
                    Эта порода была специально создана для разведения мясных овец в наиболее засушливых регионах страны.
                    </p>
              
                  <p className="text-justify">
                    В настоящее время порода Дорпер широко распространена в Южной Африке и стала там второй по численности поголовья. 
                    Дорпер – скороспелая мясная порода овец. Особую популярность в мире эта порода завоевала благодаря способности полностью линять в конце весны – в начале лета. 
                    То есть, этих овец не надо стричь, а стрижка, как известно, очень трудоемкое занятие. Дорперы бывают полностью белые и белые с черной головой.
                  </p>
                </div>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={DorperGoatfeature} className="w-100" style={{maxWidth:"500px"}}  
                                                            alt="Фото пророды овец Дорпер" 
                                                            title="Фото пророды овец Дорпер" />
              </Col>
            </Row>
        </Container>
        
         <Container fluid className="mt-3">
           <Row>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Средний выход ягнят на матку 1,6. Порода очень скороспелая. Бараны начинают работать уже в 5 – 6 месяцев. 
                За 100 дней барашек достигает веса в 36 кг. Дорперы хорошо адаптируются к различным климатическим условиям. 
                Они подходят как для выпасов, так и для интенсивного откорма на фермах. Сейчас, когда шерсть постепенно падает в цене, 
                имеет смысл работать баранами породы Дорпер на отарах мериносов методом заместительного скрещивания.
              </p>
            </Col>
            <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify" className="text-justify">
                Дорпер менее требователен к кормам по сравнению с мериносами и может есть те травы, которые мериносы едят редко. 
                У Дорперов очень толстая кожа, которая защищает овец в суровых климатических условиях. Дорперам не страшны укусы насекомых, 
                колючки на пастбищах, их не надо стричь. Кожа Дорпера самая качественная в мире и продается под названием Кейп Гловерс (Cape Glovers). 
                Примерно 20% дохода Южно-Африканских овцеводов составляет продажа кожи Дорперов.
              </p>
            </Col>
           </Row>
         </Container>
        <Container fluid className="mt-3">
          <h2 className="text-center">Фото овец породы Дорпер</h2>
          <ImagesView galleryImage={galleryImage} />
        </Container>
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default DorperePoroda;